<template>
  <div
    class="article"
    :class="{ active: fullScreen == true }"
    :style="`background-color: ${articleColor};`"
  >
    <div class="tbl_wrap">
      <div class="tbl_option">
        <button
          class="btn_sub1"
          @click="fullScreen = !fullScreen"
          :style="`background-color: ${btnColor}; color: ${btnFontColor};`"
        >
          {{ fullScreen ? '닫기' : '전체화면' }}
        </button>
      </div>

      <div class="table_wrap">
        <div class="mes_tbl_wrap" :style="`border: 2px solid ${mesborTbl}`">
          <table class="mes_tbl">
            <thead>
              <tr>
                <th
                  colspan="3"
                  :style="
                    `border-bottom: 2px solid ${mesboriTbl}; background-color: ${tempbgColor}; color: ${tempFont};`
                  "
                >
                  온습도 현황
                  <span class="date">{{
                    grouped_temphumi_logs.length == 0
                      ? ''
                      : yyyy년MM월dd일HH시mm분ss초2(
                          grouped_temphumi_logs[0].create_time,
                        )
                  }}</span>
                </th>
              </tr>
              <tr>
                <th
                  :style="
                    `border-bottom: 2px solid ${mesboriTbl}; background-color: ${divColor}; color: ${divFont};`
                  "
                >
                  구분
                </th>
                <th
                  :style="
                    `border-left: 2px solid ${mesboriTbl}; border-bottom: 2px solid ${mesboriTbl}; background-color: ${divColor}; color: ${divFont};`
                  "
                >
                  온도
                </th>
                <th
                  :style="
                    `border-left: 2px solid ${mesboriTbl}; border-bottom: 2px solid ${mesboriTbl}; background-color: ${divColor}; color: ${divFont};`
                  "
                >
                  습도
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in grouped_temphumi_logs" :key="item.id">
                <td
                  :style="
                    grouped_temphumi_logs.length - 1 == index
                      ? `border-left: 0; border-bottom: 0; background-color: ${tableColor}; color: ${detailFont};`
                      : `border-left: 0; border-bottom: 2px solid ${mesboriTbl};
                    background-color: ${tableColor}; color: ${detailFont};`
                  "
                >
                  {{
                    `${item.plc_location_name}: ${item.plc_sensor_type_name}`
                  }}
                </td>
                <td
                  :style="
                    grouped_temphumi_logs.length - 1 == index
                      ? `border-left: 2px solid${mesboriTbl}; border-bottom: 0; background-color: ${tableColor}; color: ${detailTempFont};`
                      : `border-left: 2px solid${mesboriTbl}; border-bottom: 2px solid ${mesboriTbl}; background-color: ${tableColor}; color: ${detailTempFont};`
                  "
                >
                  {{ item.value }}℃
                </td>
                <td
                  :style="
                    grouped_temphumi_logs.length - 1 == index
                      ? `border-left: 2px solid${mesboriTbl}; border-bottom: 0; background-color: ${tableColor}; color: ${detailTempFont};`
                      : `border-left: 2px solid${mesboriTbl}; border-bottom: 2px solid ${mesboriTbl}; background-color: ${tableColor}; color: ${detailTempFont};`
                  "
                >
                  {{ item.humi_value }}%
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="mes_tbl_wrap" :style="`border: 2px solid ${mesborTbl};`">
          <table class="mes_tbl">
            <thead>
              <tr>
                <th
                  colspan="3"
                  :style="
                    `border-bottom: 2px solid ${mesboriTbl}; background-color: ${tempbgColor}; color: ${tempFont};`
                  "
                >
                  온도 현황
                  <span class="date"></span>
                </th>
              </tr>
              <tr>
                <th
                  :style="
                    `border-bottom: 2px solid ${mesboriTbl}; background-color: ${divColor}; color: ${divFont};`
                  "
                >
                  구분
                </th>
                <th
                  :style="
                    `border-left: 2px solid ${mesboriTbl}; border-bottom: 2px solid ${mesboriTbl}; background-color: ${divColor}; color: ${divFont};`
                  "
                >
                  온도
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in sorted_plc_temperature_logs"
                :key="item.id"
              >
                <td
                  :style="
                    sorted_plc_temperature_logs.length - 1 == index
                      ? `border-left: 0; background-color: ${tableColor}; color: ${detailFont};`
                      : `border-left: 0; border-bottom: 2px solid ${mesboriTbl};
                    background-color: ${tableColor}; color: ${detailFont};`
                  "
                >
                  {{
                    `${item.plc_location_name}: ${item.plc_sensor_type_name}`
                  }}
                </td>
                <td
                  :style="
                    sorted_plc_temperature_logs.length - 1 == index
                      ? `border-left: 2px solid${mesboriTbl}; background-color: ${tableColor}; color: ${detailTempFont};`
                      : `border-left: 2px solid${mesboriTbl}; border-bottom: 2px solid ${mesboriTbl}; background-color: ${tableColor}; color: ${detailTempFont};`
                  "
                >
                  {{ item.value }}℃
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { yyyy년MM월dd일HH시mm분ss초 } from '@/utils/func';
import fetchMixin from '@/mixins/fetch';
import { mapGetters } from 'vuex';

export default {
  mixins: [fetchMixin],
  data() {
    return {
      fullScreen: false,
      str_time: null,
      timerCount: 10,
      timerMax: 10,
      interval: null,
      timeInterval: null,
      currentTimeInterval: null,

      articleColor: localStorage.getItem('articleColor'), //배경
      mesborTbl: localStorage.getItem('mesborTbl'), //테두리
      mesboriTbl: localStorage.getItem('mesboriTbl'), //테두리 내부
      tempbgColor: localStorage.getItem('tempbgColor'), //온습도현황 배경
      tempFont: localStorage.getItem('tempFont'), //온습도현황 폰트
      divColor: localStorage.getItem('divColor'), // 구분 온습도 배경
      divFont: localStorage.getItem('divFont'), //구분 온습도 폰트
      tableColor: localStorage.getItem('tableColor'), //테이블 배경
      detailFont: localStorage.getItem('detailFont'), //데이터 구분 폰트
      detailTempFont: localStorage.getItem('detailTempFont'), //데이터 온습도 폰트
      btnColor: localStorage.getItem('btnColor'), //버튼 배경
      btnFontColor: localStorage.getItem('btnFontColor'), //버튼 폰트
    };
  },
  computed: {
    ...mapGetters({
      plc_sensor_logs: 'getPlcSensorLog',
      plc_temperature_logs: 'getTemperatureSensorLog',
      plc_tempHumi_logs: 'getTemperatureHumiditySensorLog',
      tabIndex: 'getOpenTabIndexFromPlcMonitorPage',
    }),
    sorted_plc_temperature_logs() {
      let clone = this.lodash.clonedeep(this.plc_temperature_logs);
      return clone.sort((a, b) => a.plc_sensor_id - b.plc_sensor_id);
    },
    grouped_temphumi_logs() {
      let clone = this.lodash.clonedeep(this.plc_tempHumi_logs);
      clone = clone.sort((a, b) => a.plc_sensor_id - b.plc_sensor_id);

      let lists = [];
      let location_id_temp = null;
      let duplicate_count = 2;
      clone.forEach((element, index) => {
        if (index % 2 == 0) {
          if (element.plc_location_id == location_id_temp) {
            element.plc_sensor_type_name =
              element.plc_sensor_type_name + duplicate_count;
            duplicate_count++;
          } else {
            duplicate_count = 2;
          }
          location_id_temp = element.plc_location_id;
          element.humi_value = clone[index + 1].value;
          lists.push(element);
        }
      });
      return lists;
    },
  },
  destroyed() {
    console.log('destroyed');
    clearInterval(this.interval);
    clearInterval(this.timeInterval);
    clearInterval(this.currentTimeInterval);
  },
  methods: {
    yyyy년MM월dd일HH시mm분ss초2(date2) {
      const date = new Date(date2);
      var MM = date.getMonth() + 1; // getMonth() is zero-based
      var dd = date.getDate();
      var hh = date.getHours();
      var mm = date.getMinutes();
      var ss = date.getSeconds();

      return [
        date.getFullYear(),
        '년 ' + (MM > 9 ? MM : '0' + MM),
        '월 ' + (dd > 9 ? dd : '0' + dd),
        '일 (' + (hh > 9 ? hh : '0' + hh),
        ':' + (mm > 9 ? mm : '0' + mm),
        ':' + (ss > 9 ? ss : '0' + ss),
        ')',
      ].join('');
    },
  },

  async created() {
    this.timerCount = this.timerMax;
    await this.FETCH_NO_SPINNER('FETCH_PLC_SENSOR_LOG_NOW', '온습도현황');

    this.interval = setInterval(async () => {
      this.timerCount = this.timerMax;

      if (this.tabIndex == 0) {
        await this.FETCH_NO_SPINNER('FETCH_PLC_SENSOR_LOG_NOW', '온습도현황');
      }
      clearInterval(this.timeInterval);
      this.timeInterval = setInterval(() => {
        this.timerCount -= 1;
      }, 1000);
    }, this.timerMax * 1000);
    this.timeInterval = setInterval(() => {
      this.timerCount -= 1;
    }, 1000);

    this.currentTimeInterval = setInterval(() => {
      this.str_time = yyyy년MM월dd일HH시mm분ss초(new Date());
    }, 1000);
  },
};
</script>

<style lang="scss" scoped></style>
