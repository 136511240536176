<template>
  <div class="article">
    <div class="head">
      <h5>현황판 색 설정</h5>
    </div>
    <div class="form">
      <form>
        <h5>실시간 온습도 현황</h5>
        <ul>
          <li>
            <div>
              <input id="thBgColor" type="color" v-model="background_pick" />
            </div>
            <label for="thBgColor">전체 배경</label>
          </li>
          <li>
            <div>
              <input id="thBdColor" type="color" v-model="border_pick" />
            </div>
            <label for="thBdColor">테이블 외부 테두리</label>
          </li>
          <li>
            <div>
              <input id="thBdiColor" type="color" v-model="border_inner" />
            </div>
            <label for="thBdiColor">테이블 내부 테두리</label>
          </li>
          <li>
            <div>
              <input id="thCrtColor" type="color" v-model="background_temp" />
            </div>
            <label for="thCrtColor">테이블 제목 배경(첫번째)</label>
          </li>
          <li>
            <div>
              <input id="thCrtFont" type="color" v-model="color_temp" />
            </div>
            <label for="thCrtFont"> 테이블 제목 글자(첫번째) </label>
          </li>
          <li>
            <div>
              <input
                id="thDivColor"
                type="color"
                v-model="background_division"
              />
            </div>
            <label for="thDivColor">테이블 제목 배경(두번째)</label>
          </li>
          <li>
            <div>
              <input id="thDivFont" type="color" v-model="color_division" />
            </div>
            <label for="thDivFont">테이블 제목 글자 (두번째)</label>
          </li>
        </ul>
        <ul>
          <li>
            <div>
              <input
                id="thTableColor"
                type="color"
                v-model="background_table"
              />
            </div>
            <label for="thTableColor">테이블 내용 배경 </label>
          </li>
          <li>
            <div>
              <input id="thDetailColor" type="color" v-model="color_detail" />
            </div>
            <label for="thDetailColor">테이블 내용 글자(구분)</label>
          </li>
          <li>
            <div>
              <input
                id="thDetailFont"
                type="color"
                v-model="color_detail_temp"
              />
            </div>
            <label for="thDetailFont">테이블 내용 글자(온도, 습도)</label>
          </li>
          <li>
            <div>
              <input id="thBtnColor" type="color" v-model="background_button" />
            </div>
            <label for="thBtnColor">전체화면 버튼 배경</label>
          </li>
          <li>
            <div>
              <input id="thBtnFont" type="color" v-model="color_button" />
            </div>
            <label for="thBtnFont">전체화면 버튼 글자</label>
          </li>
        </ul>
        <div class="btn_wrap">
          <button
            id="tempColor"
            class="btn_sub2"
            @click.prevent="showModal($event)"
          >
            초기화
          </button>
          <button class="btn_sub2" @click.prevent="submitFormTH">저장</button>
        </div>
      </form>
      <form>
        <h5>실시간 금속검출기 현황</h5>
        <ul>
          <li>
            <div>
              <input id="metalBgColor" type="color" v-model="background_bad" />
            </div>
            <label for="metalBgColor">전체 배경</label>
          </li>
          <li>
            <div>
              <input id="metalBtnColor" type="color" v-model="background_btn" />
            </div>
            <label for="metalBtnColor">전체화면 버튼 배경</label>
          </li>
          <li>
            <div>
              <input id="metalBtnFont" type="color" v-model="color_btn" />
            </div>
            <label for="metalBtnFont">전체화면 버튼 글자</label>
          </li>
          <li>
            <div>
              <input id="metalErrFont" type="color" v-model="color_current" />
            </div>
            <label for="metalErrFont">제목/조회시간 글자</label>
          </li>
          <li>
            <div>
              <input
                id="metalPsCard"
                type="color"
                v-model="background_process_card"
              />
            </div>
            <label for="metalPsCard">전체 카드 배경</label>
          </li>
          <li>
            <div>
              <input
                id="metalPsFont"
                type="color"
                v-model="color_process_card"
              />
            </div>
            <label for="metalPsFont">진행 카드 글자</label>
          </li>
          <li>
            <div>
              <input
                id="metalSsFont"
                type="color"
                v-model="color_success_card"
              />
            </div>
            <label for="metalSsFont">양품 카드 글자</label>
          </li>
          <li>
            <div>
              <input id="metalBdFont" type="color" v-model="color_bad_card" />
            </div>
            <label for="metalBdFont">불량 카드 글자</label>
          </li>
        </ul>
        <div class="btn_wrap">
          <button
            id="metalColor"
            class="btn_sub2"
            @click.prevent="showModal($event)"
          >
            초기화
          </button>
          <button class="btn_sub2" @click.prevent="submitFormMetal">
            저장
          </button>
        </div>
      </form>
    </div>
    <two-button-modal
      :modal_title="my_modal_title"
      :modal_content="my_modal_content"
      :isModalOpen="my_isModalOpen"
      @onclose="CloseModal"
      @OnYesClick="removeTH()"
    >
    </two-button-modal>
  </div>
</template>

<script>
import ModalMixin from '@/mixins/modal';
import TwoButtonModal from '@/layouts/components/TwoButtonModal';
export default {
  mixins: [ModalMixin],
  components: {
    TwoButtonModal,
  },
  data() {
    return {
      //실시간 온습도 현황 Color
      background_pick: '#1e2242',
      border_pick: '#1e2242',
      border_inner: '#7f84aa',
      background_temp: '#2f3664',
      color_temp: '#ffffff',
      background_division: '#252b54',
      color_division: '#81c4ff',
      background_table: '#3b427a',
      color_detail: '#ffffff',
      color_detail_temp: '#f98787',
      background_button: '#3b427a',
      color_button: '#ffffff',

      //실시간 금속검출기 현황 Color
      background_bad: '#1e2242',
      background_btn: '#3b427a',
      color_btn: '#ffffff',
      color_current: '#ffffff',
      background_process_card: '#3b427a',
      color_process_card: '#ffffff',
      color_success_card: '#7ca4d9',
      color_bad_card: '#ef6262',

      //reset modal
      my_modal_title: '',
      my_modal_content: '',
      my_modal_index: -1,
      my_isModalOpen: false,
      tempColor: false,
      metalColor: false,
    };
  },
  mounted() {
    this.background_pick = localStorage.getItem('articleColor');
    this.border_pick = localStorage.getItem('mesborTbl');
    this.border_inner = localStorage.getItem('mesboriTbl');
    this.background_temp = localStorage.getItem('tempbgColor');
    this.color_temp = localStorage.getItem('tempFont');
    this.background_division = localStorage.getItem('divColor');
    this.color_division = localStorage.getItem('divFont');
    this.background_table = localStorage.getItem('tableColor');
    this.color_detail = localStorage.getItem('detailFont');
    this.color_detail_temp = localStorage.getItem('detailTempFont');
    this.background_button = localStorage.getItem('btnColor');
    this.color_button = localStorage.getItem('btnFontColor');

    this.background_bad = localStorage.getItem('bgBad');
    this.background_btn = localStorage.getItem('btnAllColor');
    this.color_btn = localStorage.getItem('btnAllFontColor');
    this.color_current = localStorage.getItem('errCurrent');
    this.background_process_card = localStorage.getItem('psBackgroundCard');
    this.color_process_card = localStorage.getItem('psCardFont');
    this.color_success_card = localStorage.getItem('ssCardFont');
    this.color_bad_card = localStorage.getItem('bdCardFont');
  },

  methods: {
    showModal(e) {
      if (e.target.id == 'tempColor') {
        this.tempColor = true;
      } else {
        this.metalColor = true;
      }

      this.my_modal_title = '알림';
      this.my_modal_content = '정말로 초기화하시겠습니까?';
      this.my_isModalOpen = true;
    },
    CloseModal() {
      this.my_modal_index = -1;
      this.my_modal_title = '';
      this.my_modal_content = '';
      this.my_isModalOpen = false;
    },
    submitFormTH() {
      localStorage.setItem('articleColor', this.background_pick);
      localStorage.setItem('mesborTbl', this.border_pick);
      localStorage.setItem('mesboriTbl', this.border_inner);
      localStorage.setItem('tempbgColor', this.background_temp);
      localStorage.setItem('tempFont', this.color_temp);
      localStorage.setItem('divColor', this.background_division);
      localStorage.setItem('divFont', this.color_division);
      localStorage.setItem('tableColor', this.background_table);
      localStorage.setItem('detailFont', this.color_detail);
      localStorage.setItem('detailTempFont', this.color_detail_temp);
      localStorage.setItem('btnColor', this.background_button);
      localStorage.setItem('btnFontColor', this.color_button);

      this.openOneButtonModal('저장 성공', '성공적으로 저장하였습니다.');
    },
    removeTH() {
      if (this.tempColor == true) {
        localStorage.setItem('articleColor', '#1e2242'); //배경
        localStorage.setItem('mesborTbl', '#1e2242'); //테두리외부
        localStorage.setItem('mesboriTbl', '#7f84aa'); //테두리내부
        localStorage.setItem('tempbgColor', '#2f3664'); //온습도현황 칼라
        localStorage.setItem('tempFont', '#ffffff'); //온습도현황 폰트
        localStorage.setItem('divColor', '#252b54'); //구분 온습도 배경
        localStorage.setItem('divFont', '#81c4ff'); //구분 온습도 폰트
        localStorage.setItem('tableColor', '#3b427a'); //테이블 칼라
        localStorage.setItem('detailFont', '#ffffff'); //온습도폰트
        localStorage.setItem('detailTempFont', '#f98787'); //구분폰트
        localStorage.setItem('btnColor', '#3b427a'); //버튼색
        localStorage.setItem('btnFontColor', '#ffffff'); // 버튼폰트

        this.background_pick = '#1e2242';
        this.border_pick = '#1e2242';
        this.border_inner = '#7f84aa';
        this.background_temp = '#2f3664';
        this.color_temp = '#ffffff';
        this.background_division = '#252b54';
        this.color_division = '#81c4ff';
        this.background_table = '#3b427a';
        this.color_detail = '#ffffff';
        this.color_detail_temp = '#f98787';
        this.background_button = '#3b427a';
        this.color_button = '#ffffff';
      } else if (this.metalColor == true) {
        // 금속검출기 초기화
        this.removeMetal();
      }
      this.tempColor = false;
      this.metalColor = false;
      this.CloseModal();
    },
    submitFormMetal() {
      localStorage.setItem('bgBad', this.background_bad);
      localStorage.setItem('btnAllColor', this.background_btn);
      localStorage.setItem('btnAllFontColor', this.color_btn);
      localStorage.setItem('errCurrent', this.color_current);
      localStorage.setItem('psBackgroundCard', this.background_process_card);
      localStorage.setItem('psCardFont', this.color_process_card);
      localStorage.setItem('ssCardFont', this.color_success_card);
      localStorage.setItem('bdCardFont', this.color_bad_card);

      this.openOneButtonModal('저장 성공', '성공적으로 저장하였습니다.');
    },
    removeMetal() {
      localStorage.setItem('bgBad', '#1e2242'); //금속검출기 배경
      localStorage.setItem('btnAllColor', '#3b427a'); //버튼색상
      localStorage.setItem('btnAllFontColor', '#ffffff'); //버튼폰트
      localStorage.setItem('errCurrent', '#ffffff'); //불량검출현황 폰트
      localStorage.setItem('psBackgroundCard', '#3b427a'); //카드 배경색상
      localStorage.setItem('psCardFont', '#ffffff'); //진행 폰트
      localStorage.setItem('ssCardFont', '#7ca4d9'); //양품 폰트
      localStorage.setItem('bdCardFont', '#ef6262'); //불량 폰트

      this.background_bad = '#1e2242';
      this.background_btn = '#3b427a';
      this.color_btn = '#ffffff';
      this.color_current = '#ffffff';
      this.background_process_card = '#3b427a';
      this.color_process_card = '#ffffff';
      this.color_success_card = '#7ca4d9';
      this.color_bad_card = '#ef6262';
    },
  },
  async created() {
    if (localStorage.getItem('articleColor') == undefined) {
      localStorage.setItem('articleColor', this.background_pick);
    }
    if (localStorage.getItem('mesborTbl') == undefined) {
      localStorage.setItem('mesborTbl', this.border_pick);
    }
    if (localStorage.getItem('mesboriTbl') == undefined) {
      localStorage.setItem('mesboriTbl', this.border_inner);
    }
    if (localStorage.getItem('tempbgColor') == undefined) {
      localStorage.setItem('tempbgColor', this.background_temp);
    }
    if (localStorage.getItem('tempFont') == undefined) {
      localStorage.setItem('tempFont', this.color_temp);
    }
    if (localStorage.getItem('divColor') == undefined) {
      localStorage.setItem('divColor', this.background_division);
    }
    if (localStorage.getItem('divFont') == undefined) {
      localStorage.setItem('divFont', this.color_division);
    }
    if (localStorage.getItem('tableColor') == undefined) {
      localStorage.setItem('tableColor', this.background_table);
    }
    if (localStorage.getItem('detailFont') == undefined) {
      localStorage.setItem('detailFont', this.color_detail);
    }
    if (localStorage.getItem('detailTempFont') == undefined) {
      localStorage.setItem('detailTempFont', this.color_detail_temp);
    }
    if (localStorage.getItem('btnColor') == undefined) {
      localStorage.setItem('btnColor', this.background_button);
    }
    if (localStorage.getItem('btnFontColor') == undefined) {
      localStorage.setItem('btnFontColor', this.color_button);
    }

    if (localStorage.getItem('bgBad') == undefined) {
      localStorage.setItem('bgBad', this.background_bad);
    }
    if (localStorage.getItem('btnAllColor') == undefined) {
      localStorage.setItem('btnAllColor', this.background_btn);
    }
    if (localStorage.getItem('btnAllFontColor') == undefined) {
      localStorage.setItem('btnAllFontColor', this.color_btn);
    }
    if (localStorage.getItem('errCurrent') == undefined) {
      localStorage.setItem('errCurrent', this.color_current);
    }
    if (localStorage.getItem('psBackgroundCard') == undefined) {
      localStorage.setItem('psBackgroundCard', this.background_process_card);
    }
    if (localStorage.getItem('psCardFont') == undefined) {
      localStorage.setItem('psCardFont', this.color_process_card);
    }
    if (localStorage.getItem('ssCardFont') == undefined) {
      localStorage.setItem('ssCardFont', this.color_success_card);
    }
    if (localStorage.getItem('bdCardFont') == undefined) {
      localStorage.setItem('bdCardFont', this.color_bad_card);
    }
  },
};
</script>

<style lang="scss" scoped></style>
